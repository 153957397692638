@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --prim-color: #3857aa;
  --sec-color: #4c68bd;
  --thr-color: #6079d0;
  --frt-color: #738ae3;
  --gray: #373737;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  margin: 0 !important;
}
